import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AdminGuard } from './core/guards/services/admin.guard'
import { PcRadioGuard } from './core/guards/services/pc-radio.guard'
import { FactoriesGuard } from './core/guards/services/factories.guard'
import { TrucksGuard } from './core/guards/services/trucks.guard'
import { StocksGuard } from './core/guards/services/stocks.guard'
import { PlanificationGuard } from './core/guards/services/planification.guard'
import { OffersGuard } from './core/guards/services/offers.guard'
import { AccessDeniedComponent, LoginComponent, SharedLogoutComponent } from '@agroone-front/shared'

const routes: Routes = [
  { path: 'access-denied', component: AccessDeniedComponent },
  {
    path: 'logout',
    component: SharedLogoutComponent,
  },
  {
    path: 'auth/login',
    component: LoginComponent,
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'home',
        loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'planification',
        loadChildren: () => import('./features/planification/planification.module').then((m) => m.PlanificationModule),
        canActivate: [PlanificationGuard],
      },
      {
        path: 'admin',
        loadChildren: () => import('./features/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'suivi',
        loadChildren: () => import('./features/stocks/stocks.module').then((m) => m.StocksModule),
        canActivate: [StocksGuard],
      },
      {
        path: 'pc-radio',
        loadChildren: () => import('./features/pc-radio/pc-radio.module').then((m) => m.PcRadioModule),
        canActivate: [PcRadioGuard],
      },
      {
        path: 'crop',
        loadChildren: () => import('./features/crop/crop.module').then((m) => m.CropModule),
        canActivate: [OffersGuard],
      },
      {
        path: 'trucks',
        loadChildren: () => import('./features/trucks/trucks.module').then((m) => m.TrucksModule),
        canActivate: [TrucksGuard],
      },
      {
        path: 'factories',
        loadChildren: () => import('./features/factories/factories.module').then((m) => m.FactoriesModule),
        canActivate: [FactoriesGuard],
      },
    ],
  },
  { path: '**', redirectTo: '' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
