import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { UserService } from '@agropilot/app/core/services/user/user.service'
import { RegionNetworkService } from './region.network.service'
import { Region } from '@agroone/entities'

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  public regions: Observable<Region[]>
  private region: Region

  private regionsSubject: BehaviorSubject<Region[]> = new BehaviorSubject<Region[]>(null)
  get currentRegions(): Region[] {
    return this.regionsSubject.getValue()
  }

  constructor(private regionNetworkService: RegionNetworkService, private userService: UserService) {
    this.regions = this.regionsSubject.asObservable()
  }

  init(): Observable<Region | Region[]> {
    return this.getRegions()
  }

  getRegions(): Observable<Region | Region[]> {
    return this.regionNetworkService.get().pipe(
      map((regions: Region[]) => {
        if (regions) {
          this.regionsSubject.next(regions)
        }
        return regions
      })
    )
  }

  get currentActiveRegion(): Region {
    if (this.region && this.region.name === this.userService.currentUser?.regionName) {
      return this.region
    }

    return undefined
  }
}
