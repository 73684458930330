import {
  AuthService,
  ErrorInterceptor,
  LoggerService,
  LoginComponent,
  MonitoringService,
  SharedConstantService,
  SharedLogoutComponent,
  VersionService,
  WithCredentialsInterceptor,
} from '@agroone-front/shared'
import { User } from '@agroone/entities'
import { environment } from '@agropilot/env/environment'
import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, isDevMode, LOCALE_ID, NgModule } from '@angular/core'
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core'
import weloopai from '@weloopai/sdk'
import { format, subDays } from 'date-fns'
import { MessageService } from 'primeng/api'
import { firstValueFrom } from 'rxjs'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CoreModule } from './core/core.module'
import { CroptypeService } from './core/services/croptype/croptype.service'
import { DirectionsNetworkService } from './core/services/directions/directions.network.service'
import { RegionService } from './core/services/region/region.service'
import { SettingsService } from './core/services/settings/settings.service'
import { UserService } from './core/services/user/user.service'
import { WebSocketsService } from './core/services/websockets/websockets.service'
import { ConstantService } from './shared/constant/constant.service'
import { HarvestOfferService } from './shared/crop/services/offer.service'
import { LoadingScreenModule } from './shared/loading-screen/loading-screen.module'
import { TranslateLocalIdFactory } from './core/translate/classes/translate-local-id-factory'
import { TranslationsLoader } from './translations.loader'
import { ServiceWorkerModule } from '@angular/service-worker'
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog'

export const init =
  (
    userService: UserService,
    regionService: RegionService,
    croptypeService: CroptypeService,
    settingsService: SettingsService,
    constantService: SharedConstantService,
    directionsService: DirectionsNetworkService,
    offerService: HarvestOfferService,
    webSocketSerivce: WebSocketsService,
    loggerService: LoggerService,
    authService: AuthService
  ) =>
  async () => {
    // todo: remove the initialization lines below after migrating them to libs/
    loggerService.init(environment.logLevel, environment.serverLogLevel, environment.local)
    authService.init(environment.cognitoDomain, environment.cognitoClientId, environment.newApiUrl)

    let user: User
    try {
      user = await firstValueFrom(userService.init())
      // Init User
      // connect to websocket
      webSocketSerivce.connect()

      // Init constant
      constantService.init()

      // Init region
      await firstValueFrom(regionService.init())

      // Init croptypes
      await firstValueFrom(croptypeService.init())

      // Init Settings
      await settingsService.init()

      // Get Direction token
      await firstValueFrom(directionsService.retrieveToken())

      await firstValueFrom(
        offerService.resetPriorisation({
          date: format(subDays(new Date(), 10), 'yyyy-MM-dd'),
        })
      )
    } catch (e) {
      console.error('App Initialisation failed due to', e)
    }

    try {
      if ((user?.email?.endsWith('@bonduelle.com') || user?.email?.endsWith('@opoasi.it')) && environment.production) {
        weloopai.init({
          key: '4a659440-6386-11ea-851c-03c00b0fdf8f',
        })
      }
    } catch (error) {
      console.error('Weloop initialization failed', error)
    }
  }

@NgModule({
  declarations: [AppComponent],
  imports: [
    LoginComponent,
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    CommonModule,
    CoreModule,
    HttpClientModule,
    SharedLogoutComponent,
    MatProgressSpinnerModule,
    MatDialogModule,
    AppRoutingModule,
    LoadingScreenModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslationsLoader,
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      multi: true,
      deps: [
        UserService,
        RegionService,
        CroptypeService,
        SettingsService,
        SharedConstantService,
        DirectionsNetworkService,
        HarvestOfferService,
        WebSocketsService,
        LoggerService,
        AuthService,
      ],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WithCredentialsInterceptor,
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useFactory: TranslateLocalIdFactory,
      deps: [TranslateService],
    },
    MessageService,
    LoggerService,
    AuthService,
    VersionService,
    MonitoringService,
    {
      provide: SharedConstantService,
      useClass: ConstantService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
