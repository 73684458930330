import { environment as environmentPrd } from './environment.prd'

export const environment = {
  ...environmentPrd,
  production: false,
  qlf: true,
  dynatraceScriptSrc: '',
  newApiUrl: 'https://apiv2.cldqlf.bonduelle.com',
  hubUrl: 'https://agroone.cldqlf.bonduelle.com',
  websocket: 'wss://ws.apiv2.cldqlf.bonduelle.com',
  localEmail: 'test@bonduelle.com',
  weloop: {
    active: false,
    guid: 'b14b45a0-6382-11ea-851c-03c00b0fdf8f',
    websiteId: '7a2521b8-3844-45a8-b63a-84bbcdbeae0b',
  },
  logLevel: 'DEBUG',
  cognitoDomain: 'https://ssoconnect.cldqlf.bnd-services.com',
  cognitoClientId: '4ui640sj1gqqf3fs64ft5mge6d',
}
