export const OffersDisplayList = [
  {
    controlCodes: [
      'preharvestControl#cob length',
      'preharvestControl#cob weight',
      'preharvestControl#moisture 1',
      'preharvestControl#moisture 2',
      'preharvestControl#moisture 3',
      'preharvestControl#recovery percentage',
    ],
    cropType: 'sweet corn',
    region: 'ALBERTA',
  },
  {
    controlCodes: ['preharvestControl#bean weight'],
    cropType: 'beans',
    region: 'FRANCE NORTH',
  },
  {
    controlCodes: ['preharvestControl#tend 1', 'preharvestControl#sieve 1', 'preharvestControl#AIS'],
    cropType: 'peas',
    region: 'FRANCE NORTH',
  },
  {
    controlCodes: ['preharvestControl#tend 1', 'preharvestControl#sieve 1', 'preharvestControl#AIS'],
    cropType: 'peas',
    region: 'FRANCE SOUTH WEST',
  },
  {
    controlCodes: ['preharvestControl#beans', 'preharvestControl#sample area', 'preharvestControl#size 1'],
    cropType: 'beans',
    region: 'ONTARIO',
  },
  {
    controlCodes: [
      'preharvestControl#cob length',
      'preharvestControl#cob weight',
      'preharvestControl#moisture 1',
      'preharvestControl#moisture 2',
      'preharvestControl#moisture 3',
      'preharvestControl#recovery percentage',
    ],
    cropType: 'sweet corn',
    region: 'ONTARIO',
  },
  {
    controlCodes: [
      'preharvestControl#cob length',
      'preharvestControl#cob weight',
      'preharvestControl#moisture 1',
      'preharvestControl#moisture 2',
      'preharvestControl#moisture 3',
      'preharvestControl#recovery percentage',
    ],
    cropType: 'sweet corn',
    region: 'QUEBEC',
  },
  {
    controlCodes: [
      'preharvestControl#cob length',
      'preharvestControl#cob weight',
      'preharvestControl#moisture 1',
      'preharvestControl#moisture 2',
      'preharvestControl#moisture 3',
      'preharvestControl#recovery percentage',
    ],
    cropType: 'sweet corn',
    region: 'WISCONSIN',
  },
]
