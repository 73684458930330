import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { UserService } from '@agropilot/app/core/services/user/user.service'
import { UserPermissions } from '@agroone/entities'

@Injectable()
export class PcRadioGuard implements CanActivate {
  constructor(private userService: UserService) {}

  canActivate(): boolean {
    if (!this.userService.currentUser) {
      return false
    } else {
      return this.userService.hasPermission(this.userService.currentUser, UserPermissions.HARVEST_PCRADIO)
    }
  }
}
