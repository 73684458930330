import { NgModule, ModuleWithProviders } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AdminGuard } from './services/admin.guard'
import { PcRadioGuard } from './services/pc-radio.guard'
import { FactoriesGuard } from './services/factories.guard'
import { TrucksGuard } from './services/trucks.guard'
import { StocksGuard } from './services/stocks.guard'
import { PlanificationGuard } from './services/planification.guard'
import { OffersGuard } from './services/offers.guard'

@NgModule({
  imports: [CommonModule],
  declarations: [],
})
export class GuardsModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: GuardsModule,
      providers: [PlanificationGuard, PcRadioGuard, StocksGuard, TrucksGuard, FactoriesGuard, AdminGuard, OffersGuard],
    }
  }
}
