import { NgModule, Optional, SkipSelf } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AppTranslateModule } from './translate/translate.module'
import { GuardsModule } from './guards/guards.module'
import { NetworkingModule } from './networking/networking.module'
import { ErrorModule } from './error/error.module'

@NgModule({
  imports: [
    CommonModule,
    AppTranslateModule,
    GuardsModule.forRoot(),
    NetworkingModule.forRoot(),
    ErrorModule.forRoot(),
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only')
    }
  }
}
