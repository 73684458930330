import { Injectable } from '@angular/core'
import { environment } from '@agropilot/env/environment'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { MsSqlPaginatedData, AgropilotRole, User } from '@agroone/entities'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Technician } from 'libs/entities/src/lib/dtos/technician'

export interface UpdateUser {
  lastname?: string
  firstname?: string
  roles?: AgropilotRole[]
  regionName?: string
}

@Injectable({
  providedIn: 'root',
})
export class UserNetworkService {
  constructor(private http: HttpClient) {}

  getUser(): Observable<User> {
    return this.http.get<User>(environment.newApiUrl + '/users/me')
  }

  get(): Observable<User[]> {
    const url = environment.newApiUrl + '/users'
    const headers: HttpHeaders = new HttpHeaders()
    headers.set('Content-type', 'application/json')
    const options = { headers }
    return this.http.get<MsSqlPaginatedData<User>>(url, options).pipe(map((v) => v.data))
  }

  getTechnicians(): Observable<Technician[]> {
    const headers: HttpHeaders = new HttpHeaders()
    headers.set('Content-type', 'application/json')

    return this.http.get<Technician[]>(`${environment.newApiUrl}/technicians`, { headers })
  }
}
