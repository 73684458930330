import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject, firstValueFrom } from 'rxjs'
import { UserService } from '../user/user.service'
import { format } from '@agropilot/app/core/library/date'
import { constants } from '@agropilot/env/constants'
import { Technician } from 'libs/entities/src/lib/dtos/technician'
import { SelectItem } from 'primeng/api'

export interface IFilters {
  cropType: string
  vegetableType: string
  date?: string
  region?: string
  technicians?: string[]
  varieties?: string[]
  type?: string
  forecastHarvestDate?: RangeFilter
  floweringDate?: RangeFilter
  plantedDate?: RangeFilter
}
export interface RangeFilter {
  start: Date
  end: Date
}

export const defaultFilters: IFilters = {
  cropType: null,
  vegetableType: null,
  region: null,
  technicians: null,
  varieties: null,
  type: null,
  forecastHarvestDate: null,
  floweringDate: null,
  plantedDate: null,
}

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  public filterSubject: BehaviorSubject<IFilters> = new BehaviorSubject(defaultFilters)
  public filters$: Observable<IFilters> = this.filterSubject.asObservable()
  get filters(): IFilters {
    return this.filterSubject.getValue()
  }

  constructor(private userService: UserService) {
    this.init()
  }

  async init() {
    // initialize observable
    if (localStorage.getItem('filterInfos')) {
      const data: IFilters = JSON.parse(localStorage.getItem('filterInfos'))
      data.date = format(new Date(), constants.dateFormat.onlyDate)
      this.filterSubject.next(data)
    } else {
      const currentUser = await firstValueFrom(this.userService.getCurrentUser())
      defaultFilters.region = currentUser.regionName
      localStorage.setItem('filterInfos', JSON.stringify(defaultFilters))
    }
  }

  getValue(): IFilters {
    return this.filterSubject.getValue()
  }

  setFilter(value: IFilters) {
    this.filterSubject.next(value)
    // Set localstorage
    const filterLocal = JSON.parse(localStorage.getItem('filterInfos'))
    const filter: IFilters = {
      cropType: value.cropType ? value.cropType : filterLocal.cropType ? filterLocal.cropType : null,
      vegetableType: value.vegetableType
        ? value.vegetableType
        : filterLocal.vegetableType
        ? filterLocal.vegetableType
        : null,
      region: value.region || value.region === '' ? value.region : filterLocal.region ? filterLocal.region : null,
      technicians: value.technicians ?? [],
      varieties: value.varieties ?? [],
      type: value.type ?? null,
      floweringDate: value.floweringDate ?? null,
      forecastHarvestDate: value.forecastHarvestDate ?? null,
      plantedDate: value.plantedDate ?? null,
    }
    localStorage.setItem('filterInfos', JSON.stringify(filter))
  }

  public techniciansByRegion(technicians: Technician[], region: string): SelectItem[] {
    const techniciansValue: SelectItem[] = technicians
      ?.filter((technician) => technician.availableRegions.find((availableRegion) => availableRegion === region))
      ?.map((technician) => ({
        label: technician.email?.split('@')?.[0],
        value: technician.email,
      }))
    return techniciansValue.sort((x, y) => x.label.localeCompare(y.label))
  }
}
